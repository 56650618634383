import {
  AutoGraphRounded,
  DashboardRounded,
  SwapHorizRounded,
  SearchRounded,
  BoltRounded,
  HomeRounded,
} from "@mui/icons-material";
import arb from "../components/EnclaveSDK/assets/crypto/ARB.png";
import op from "../components/EnclaveSDK/assets/crypto/OP.png";
import base from "../components/EnclaveSDK/assets/crypto/base.png";
import avax from "../components/EnclaveSDK/assets/crypto/avalanche.png";

export const USDC_CONTRACT_ADDRESS =
  "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d";
export const AAVE_POOL = "0xBfC91D59fdAA134A4ED45f7B584cAf96D7792Eff";

export const NATIVE_TOKEN_ADDRESS =
  "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

export const tokens = {
  USDC: {
    1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    421614: "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d",
    11155420: "0x5fd84259d66Cd46123540766Be93DFE6D43130D7",
    10: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    42161: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    8453: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913"
  },
  WETH: {
    1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    421614: "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73",
    11155420: "0x4200000000000000000000000000000000000006",
    10: "0x4200000000000000000000000000000000000006",
    42161: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
  },
  USDCe: {
    42161: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    10: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
  },
};

export const NAV_OPTIONS = [
  {
    label: "Explore",
    icon: <SearchRounded style={{ fontSize: "30px" }} />,
    pathname: "/",
  },
  {
    label: "Dashboard",
    icon: <HomeRounded style={{ fontSize: "30px" }} />,
    pathname: "/dashboard",
  },
  //   {
  //     label: "Swap",
  //     icon: <SwapHorizRounded />,
  //     pathname: "/swap",
  //   },
  // {
  // 	label: "Earn",
  // 	icon: <AutoGraphRounded />,
  // 	pathname: "/earn",
  // },
  {
    label: "Activity",
    icon: <BoltRounded style={{ fontSize: "30px" }} />,
    pathname: "/activity",
  },
  // {
  // 	label: "NFTs",
  // 	icon: <ImageRounded />,
  // 	pathname: "/nft",
  // },
];

export const networkNameToChainId = {
  arbitrum: 42161,
  optimism: 10,
  base: 8453,
  avalanche: 43114,
};

export const networkDetailsByNameKey = {
  arbitrum: {
    id: 42161,
    logo: arb,
    name: "Arbitrum",
  },
  optimism: {
    id: 10,
    logo: op,
    name: "Optimism",
  },
  base: {
    id: 8453,
    logo: base,
    name: "Base",
  },
  "avalanche-c": {
    id: 43114,
    logo: avax,
    name: "Avalanche C-Chain",
  },
};

export const dummyWallet = "0x1234567890abcdef1234567890abcdef12345678";
