import { createContext, useContext, useEffect, useState, useRef } from "react";
import { getAllBalances } from "../EnclaveUtils/bungeeFunction";
import { enabledNetworks } from "../EnclaveUtils/constants";
import { scanYieldBalances, processYieldBalances, processYieldOpps } from "../../../utils/stakeKitFunctions/main";
import { getAllTokens, getHotTokens, getNativeTokenPriceData, getStakeOpportunities } from "../../../utils/data";
import { getBalancesForUser, getTransactionList } from "../EnclaveUtils/functions";
import { processBalances } from "../../../utils/functions";
import { getTokenPrice } from "../../../utils/relayFunctions";
import { ethers } from "ethers";
import { getNetworkBasedPriceMap, getWalletPortfolio } from "../../../utils/birdeye";
import { NATIVE_TOKEN_ADDRESS, networkNameToChainId } from "../../../utils/constants";
import { getMultiEtherBalance } from "../EnclaveUtils/functions";
import { getSmartBalance } from "../EnclaveUtils/functionsV3";
import { useNavigate } from "react-router-dom";
const EnclaveConnect = createContext();
const EnclaveApi = createContext();



const getPrimaryStakedAssetDetails = (yieldBalances) => {
    const result = [];

    for (const [tokenSymbol, tokenData] of Object.entries(yieldBalances)) {
        const { opportunities } = tokenData;

        for (const [opportunityId, opportunityData] of Object.entries(opportunities)) {
            const { primaryStakedAssetDetails, network } = opportunityData;

            if (primaryStakedAssetDetails && primaryStakedAssetDetails.token) {
                result.push({
                    tokenSymbol,
                    opportunityId,
                    address: primaryStakedAssetDetails.token.address,
                    chainId: networkNameToChainId[network]
                });
            }
        }
    }

    return result;
};

const filterBalancesWithPrimaryStakedAssets = (balances, primaryStakedAssets) => {
    const primaryStakedAssetSet = new Set(
        primaryStakedAssets.map(asset => `${asset.address.toLowerCase()}-${asset.chainId}`)
    );

    return balances.filter(balance => {
        const balanceKey = `${balance.address.toLowerCase()}-${balance.chainId}`;
        return !primaryStakedAssetSet.has(balanceKey);
    });
};

const calculateYieldBalancesValue = (yieldBalances, chainTokenPriceListMap) => {
    let yieldValue = 0;
    for (const tokenData of Object.values(yieldBalances)) {
        for (const opportunity of Object.values(tokenData.opportunities)) {
            const { underlyingValue, network } = opportunity;
            const chainId = networkNameToChainId[network];
            const tokenAddress = tokenData.tokenDetails.address;
            const tokenPrice = chainTokenPriceListMap[chainId]?.[tokenAddress]?.value ?? 0;
            yieldValue += parseFloat(underlyingValue) * parseFloat(tokenPrice);
        }
    }
    return yieldValue;
};

export default function EnclaveConnectProvider({ children }) {

    const navigate = useNavigate();
    const [walletAddress, setWalletAddress] = useState(() => {
        let loc = window.localStorage.getItem("walletAddress");
        return ethers.isAddress(loc) ? loc : "";
    });
    const [userName, setUserName] = useState(() => {
        return window.localStorage.getItem("userName") || "";
    });
    const [userData, setUserData] = useState(() => {
        let loc = window.localStorage.getItem("userData");
        return JSON.parse(loc);
    });

    const [balancesLoading, setBalancesLoading] = useState(true);
    const [tokenListLoading, setTokenListLoading] = useState(true);
    const [activityLoading, setActivityLoading] = useState(true);
    const [yieldOppLoading, setYieldOppLoading] = useState(true);
    const [yieldBalancesLoading, setYieldBalancesLoading] = useState(true);

    const [smartBalanceObject, setSmartBalanceObject] = useState({
        netBalance: 0,
        balanceByNetwork: []
    });

    const [balances, setBalances] = useState([]);
    const [yieldBalances, setYieldBalances] = useState({});
    const [yieldOpportunities, setYieldOpportunities] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [allTokensList, setAllTokensList] = useState([]);

    const [selectedToken, setSelectedToken] = useState({});

    const [stripeOnRamp, setStripeOnRamp] = useState(null);

    const [portfolioValue, setPortfolioValue] = useState({
        value: 0,
        loading: true
    });

    const userPollIntervalRef = useRef(null);
    const genPollIntervalRef = useRef(null);

    const disconnect = () => {
        window.localStorage.removeItem("walletAddress");
        window.localStorage.removeItem("userName");
        window.localStorage.removeItem("userData");
        window.localStorage.removeItem("ethbalances");
        window.localStorage.removeItem("lastFetched");
        window.localStorage.removeItem("userbalances");
        window.localStorage.removeItem("balances");
        window.localStorage.removeItem("yieldBalances");
        window.localStorage.removeItem("stakeOpportunities");
        window.localStorage.removeItem("transactions");
        window.localStorage.removeItem("hotTokens");
        setWalletAddress("");
        setBalances([]);
        setUserName("");
        setUserData({});
        navigate('/')
    };



    const fetchTokens = async () => {
        try {
            const allTokens = await getAllTokens();
            if (allTokens && allTokens.length > 0) {
                setAllTokensList(allTokens);
                window.localStorage.setItem("allTokens", JSON.stringify(allTokens));
            }
        } catch (e) {
            console.error("Error fetching token lists: ", e);
        } finally {
            setTokenListLoading(false);
        }
    };

    const fetchActivity = async () => {
        try {
            if (userName) {
                const userTransactionsResult = await getTransactionList(userName);
                setTransactions(userTransactionsResult);
                window.localStorage.setItem("transactions", JSON.stringify(userTransactionsResult));
            }
        } catch (e) {
            console.error("Error fetching activity: ", e);
        } finally {
            setActivityLoading(false);
        }
    };

    const fetchBalances = async () => {
        try {
            // if (allTokensList.length > 0) {
            // } else {
            //     let allTokens;
            //     try {
            //         allTokens = await getAllTokens();
            //         if (allTokens && allTokens.length > 0) {
            //             setAllTokensList(allTokens);
            //             window.localStorage.setItem("allTokens", JSON.stringify(allTokens));
            //         }
            //     } catch (e) {
            //         console.error("Error fetching token lists: ", e);
            //     } finally {
            //         setTokenListLoading(false);
            //     }
            // }
            
            if (walletAddress && ethers.isAddress(walletAddress)) {
                // UNCOMMENT FOR LOCAL TESTING
                // const balancesResponse = await getWalletPortfolio(walletAddress);

                // UNCOMMENT FOR PROD
                const balancesResponse = await getBalancesForUser(userName);

                const smartBalance = await getSmartBalance(walletAddress);
                setSmartBalanceObject(smartBalance);

                if (balancesResponse && balancesResponse.length > 0) {
                    let filteredBalances = balancesResponse.filter((balance) => {
                        return enabledNetworks.includes(balance.chainId);
                    });
                    let portVal = 0;
                    for (let i = 0; i < filteredBalances.length; i++) {
                        let balance = filteredBalances[i];
                        portVal+= balance.valueUsd
                    }
                    setBalances(filteredBalances);
                    setPortfolioValue({
                        loading: false,
                        value: portVal + parseInt(smartBalance.netBalance) / 1000000
                    });
                } else {
                    setBalances([]);
                    setPortfolioValue({
                        loading: false,
                        value: parseInt(smartBalance.netBalance) / 1000000
                    });
                }
            }
        } catch (e) {
            console.error("Error fetching token balances: ", e);
        } finally {
            setBalancesLoading(false);
            setYieldBalancesLoading(false);
        }
    };

    useEffect(() => {
        if(window.localStorage.getItem("userData")) {
            setUserData(JSON.parse(window.localStorage.getItem("userData")))
        }
    }, []);

    useEffect(() => {
        if (userName !== undefined && userName !== "") {
            window.localStorage.setItem("userName", userName)
        }
        if (walletAddress !== undefined && walletAddress !== "") {
            window.localStorage.setItem("walletAddress", walletAddress)
        }
    }, [userName, walletAddress])


    const startPollingGenData = () => {
        if (genPollIntervalRef.current) {
            clearInterval(genPollIntervalRef.current);
        }
        genPollIntervalRef.current = setInterval(async () => {
            fetchTokens();
        }, 60000);
    };

    const stopPollingGenData = () => {
        if (genPollIntervalRef.current) {
            clearInterval(genPollIntervalRef.current);
            genPollIntervalRef.current = null;
        }
    };

    const startPollingUserData = () => {
        if (userPollIntervalRef.current) {
            clearInterval(userPollIntervalRef.current);
        }
        userPollIntervalRef.current = setInterval(async () => {
            fetchBalances();
            fetchActivity();
        }, 60000);
    };

    const stopPollingUserData = () => {
        if (userPollIntervalRef.current) {
            clearInterval(userPollIntervalRef.current);
            userPollIntervalRef.current = null;
        }
    };

    useEffect(() => {
        // fetchYieldOpps();
        fetchTokens();
        if (walletAddress) {
            setBalancesLoading(true);
            setYieldBalancesLoading(true);
            setActivityLoading(true);
            fetchBalances(); // Fetch immediately on mount or when walletAddress changes
            fetchActivity();
            startPollingUserData();
        } else {
            setBalancesLoading(false);
            setYieldBalancesLoading(false);
            setActivityLoading(false);
            stopPollingUserData();
        }
        startPollingGenData();
        return () => {
            stopPollingUserData();
            stopPollingGenData();
        } // Clean up on unmount
    }, [walletAddress]);

    return (
        <EnclaveConnect.Provider value={{
            walletAddress, setWalletAddress,
            userName, setUserName,
            userData, setUserData,
            disconnect
        }} >
            <EnclaveApi.Provider value={{
                userData,
                walletAddress,
                userName,
                balances: filterBalancesWithPrimaryStakedAssets(balances, getPrimaryStakedAssetDetails(yieldBalances)),
                fetchBalances,
                yieldBalances,
                yieldOpportunities,
                transactions,
                allTokensList,

                balancesLoading,
                yieldBalancesLoading,
                activityLoading,
                yieldOppLoading,
                tokenListLoading,

                smartBalanceObject,

                portfolioValue,

                stripeOnRamp,
                setStripeOnRamp,

                selectedToken,
                setSelectedToken,
                disconnect
            }} >
                {children}
            </EnclaveApi.Provider>
        </EnclaveConnect.Provider>
    );
}

export const useEnclaveConnect = () => useContext(EnclaveConnect)
export const useEnclaveApi = () => useContext(EnclaveApi)
