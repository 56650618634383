import React, { useState, useRef, useEffect } from "react";
import styles from "./css/swapTokenSelect.module.css"; // Import your CSS module
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Search } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NetworkDropdown from "./SwapNetworkDropdown";
import { ethers } from "ethers";
// import dollarlogo from "../../assets/dollar_jpeg 1.png";
import dollarlogo from "../assets/dollar_jpeg 1.png"
import { useEnclaveApi } from "./EnclaveSDK/context/EnclaveConnectProvider";

const SwapTokenSelect = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const [filteredTokens, setFilteredTokens] = useState(props.tokenList);

  const changeAllowed = props.changeAllowed;

  const balanceTokens = Object.keys(props.balances);

  const { smartBalanceObject } = useEnclaveApi();

  // Sort tokens by balance
  const sortedTokens = filteredTokens.sort((a, b) => {
    const balanceA = balanceTokens.includes(a.address.toLowerCase())
      ? props.balances[a.address.toLowerCase()].total
      : 0;
    const balanceB = balanceTokens.includes(b.address.toLowerCase())
      ? props.balances[b.address.toLowerCase()].total
      : 0;
    return balanceB - balanceA;
  });

  const newSortedTokens = [{
    address: "0xEnclave", // Example address for cabUSD
    chainId: props.selectedNetwork.id, // Optimism
    chainIds: [{
      chainId: props.selectedNetwork.id,
      name: props.selectedNetwork.name
    }],
    coingeckoId: "",
    decimals: 18,
    description: "",
    fdv: 1000000, // Example market cap
    logoURI: dollarlogo, // Replace with actual logo URL
    mc: 1000000,
    name: "Cash",
    price: 1.00,
    priceChange24h: 0,
    symbol: "USD",
    totalSupply: ethers.parseUnits("1000000", 18).toString(), // 1M total supply
    v24hUSD: 100000,
    __v: 0,
    _id: "cabusd-token-id"
  }, ...sortedTokens];

  const toggleTokenDropdown = () => {
    if (changeAllowed) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const selectToken = (token) => {
    props.setSelectedToken(token);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filteredTokens]);

  useEffect(() => {
    setFilteredTokens(
      props.tokenList.filter((token) => {
        const match =
          token.symbol.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          token.name?.toLowerCase().startsWith(searchTerm.toLowerCase());
        return match;
      })
    );
  }, [searchTerm, props.tokenList]);

  return (
    <>
      <div className={styles.container}>
        {/* Token Dropdown */}
        <div className={styles.dropdown} onClick={toggleTokenDropdown}>
          <div className={styles.tokenLogo2box}>
            {(props.selectedToken.logoURI ?? props.selectedToken.icon) && (
              <>
                <img
                  className={styles.tokenLogo}
                  src={props.selectedToken.logoURI ?? props.selectedToken.icon}
                  alt={props.selectedToken.symbol}
                />
                <img
                  className={styles.networkLogo}
                  src={props.selectedNetwork.logo ?? props.selectedToken.logo}
                  alt={props.selectedToken.symbol}
                />
              </>
            )}
            <div>
              <div>
                {" "}
                {props.type} {props.selectedToken.symbol}
              </div>
              <p
                className={styles.networkText}
              >{`On ${props?.selectedNetwork?.name} `}</p>
            </div>
          </div>

          <div className={styles.dropdownArrowBox}>
            <div>
              <p>
                {Number(props.amount) === 0
                  ? "0"
                  : Number(props.amount).toFixed(6)}{" "}
                {props.selectedToken.symbol}
              </p>
            </div>
            <>{changeAllowed && <KeyboardArrowRightIcon />}</>
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <div className={styles.main}>
          <div className={styles.dropdownMenu} ref={dropdownRef}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", gap: "6px" }}>
                <span onClick={() => setIsDropdownOpen(false)}>
                  <ArrowBackIcon />
                </span>
                <span style={{ fontSize: "20px" }}>Select a token</span>
              </div>
              <div>
                <NetworkDropdown
                  networkList={props.networkList}
                  selectedNetwork={props.selectedNetwork}
                  setSelectedNetwork={props.setSelectedNetwork}
                />
              </div>
            </div>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul className={styles.options}>
              {sortedTokens.length == 0 && (
                <div className={styles.emptyList}>
                  <Search />
                  <br />
                  No tokens found
                </div>
              )}
              {newSortedTokens.map((token) => {
                return (
                  <li
                    key={token.address}
                    className={styles.dropdownItem}
                    onClick={() => selectToken(token)}
                  >
                    <div className={styles.tokenDetails}>
                      {(token.logoURI ?? token.icon) && (
                        <img
                          src={token.logoURI ?? token.icon}
                          alt={token.symbol}
                        />
                      )}
                      <div>
                        <span>{token.name ?? token.symbol}</span>
                        <br />
                        <span style={{ fontSize: "0.8rem", opacity: 0.7 }}>
                          {token.symbol}
                        </span>
                      </div>
                    </div>
                    <div>
                      {token.address.toLowerCase() == '0xEnclave' ? 
                      <span>
                        {props.smartBalanceObject.netBalance / 1e6}
                      </span> :
                      props.balances[
                        token?.chainIds
                          ?.find(
                            (chain) =>
                              chain.chainId === props.selectedNetwork.id
                          )
                          ?.address?.toLowerCase()
                      ] && (
                        <span>
                          {parseInt(
                            props.balances[
                              token?.chainIds
                                ?.find(
                                  (chain) =>
                                    chain.chainId === props.selectedNetwork.id
                                )
                                ?.address?.toLowerCase()
                            ].total * 100000
                          ) / 100000}
                        </span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SwapTokenSelect;
