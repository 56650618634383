import React, { useState, useEffect, useMemo } from "react";
import Numpad from "../Numpad";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { ethers } from "ethers";
import { add, debounce } from "lodash";
import {
  getERC20TransferCallData,
  getWalletAddressForUsername,
} from "../EnclaveSDK/EnclaveUtils/functions";
import arb from "../EnclaveSDK/assets/crypto/ARB.png";
import { convertToNetworkTokenList } from "../../utils/tokenListUtils";
import { processBalances2 } from "../../utils/functions";
import styles from "./common.module.css";
import Dropdown from "../SwapTokenDropdown";
import SwapTokenSelect from "../SwapTokenSelect";
import { networkDetailsByNameKey, tokens } from "../../utils/constants";
import { ArrowBack, ArrowDownwardOutlined, GasMeter } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { BadgeCheck } from "lucide-react";
import {
  enabledNetworks,
  networkDetails,
} from "../EnclaveSDK/EnclaveUtils/constants";
import {computeQuote} from "../EnclaveSDK/EnclaveUtils/functionsV3";

const networkList = Object.values(networkDetailsByNameKey);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TransferPage() {

  const query = useQuery();

  const [cashTransferMode, setCashTransferMode] = useState(true);

  const { balances, allTokensList, walletAddress, smartBalanceObject } = useEnclaveApi();

  const pdestinationNetwork = query.get("pdestinationNetwork");
  const pdestinationAddress = query.get("pdestinationAddress");
  // const [destinationNetwork, setDestinationNetwork] = useState(
  //   pdestinationNetwork
  //     ? networkDetails[parseInt(pdestinationNetwork)]
  //     : networkDetails[enabledNetworks[1]]
  // );

  const [sourceNetwork, setSourceNetwork] = useState(
    pdestinationNetwork
      ? networkDetails[parseInt(pdestinationNetwork)]
      : networkDetails[enabledNetworks[1]]
  );

  const destinationTokenList = useMemo(
    () => convertToNetworkTokenList(sourceNetwork.id, allTokensList),
    [sourceNetwork.id, allTokensList]
  );

  const [fromToken, setFromToken] = useState(null);

  useEffect(() => {
    if (!fromToken && destinationTokenList?.length > 0) {
      if (pdestinationAddress) {
        const foundToken = destinationTokenList.find(
          (token) =>
            token.address.toLowerCase() === pdestinationAddress.toLowerCase()
        );
        if (foundToken) {
          setFromToken(foundToken);
        } else {
          setFromToken(destinationTokenList[0]);
        }
      } else {
        setFromToken(destinationTokenList[0]);
      }
    }
  }, [destinationTokenList, pdestinationAddress]);

  const [sourceNetworkBalances, setSourceNetworkBalances] = useState({});
  const [calldata, setCalldata] = useState("0x");
  const [sourceAmount, setSourceAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  console.log("ERROR: ", error);

  const [address, setAddress] = useState("");
  const [sendToUser, setSendToUser] = useState({
    status: false,
    username: undefined,
    walletAddress: undefined,
  });
  const [addressToSend, setAddressToSend] = useState("");

  console.log("Send to user: ", sendToUser);
  console.log("Address to send: ", addressToSend);

  const txnChainId = sourceNetwork.id;
  console.log("A. Txn Chain ID: ", txnChainId);
  const balanceByChainId = parseInt(smartBalanceObject.balanceByNetwork.find((balanceObj) => balanceObj.network === txnChainId)?.value ?? 0) / 1000000;
  console.log("B. balanceByChainId: ", balanceByChainId);
  const deficit =  fromToken?.address == '0xEnclave' ? (parseFloat(sourceAmount) - balanceByChainId < 0 ? 0 : parseFloat(sourceAmount) - balanceByChainId) : undefined;
  console.log("C. Deficit: ", deficit);
  const orderData = fromToken?.address =='0xEnclave' ? {
    amount: Math.floor(sourceAmount * 1000000),
    type: "AMOUNT_OUT"
  } : undefined;
  console.log("D. orderData: ", orderData);
  
  const validAmount = parseFloat(sourceAmount) > 0;
  const withinBalance =   fromToken?.address == '0xEnclave' ? parseFloat(sourceAmount) <= parseFloat(smartBalanceObject.netBalance) / 1000000 : parseFloat(sourceAmount) <= parseFloat(
      sourceNetworkBalances[
        fromToken?.chainIds
          ?.find((chain) => chain.chainId === sourceNetwork.id)
          ?.address?.toLowerCase()
      ]?.total
    );
  const validToAddress = ethers.isAddress(addressToSend);
  const validSend = validAmount && withinBalance && validToAddress && !error;

  const navigate = useNavigate();

  const handleAddressChange = async (value) => {
    setAddress(value);
    if (value && value.length > 0) {
      setLoading(true);
      const res = await getWalletAddressForUsername(value);
      console.log("Result: ", res);
      if (res.value) {
        setSendToUser({
          status: true,
          username: value,
          walletAddress: res.walletAddress,
        });
        setAddressToSend(res.walletAddress);
      } else {
        setSendToUser({
          status: false,
          username: undefined,
          walletAddress: undefined,
        });
        setAddressToSend(value);
      }
    }
  };

  console.log({ balances });
  useEffect(() => {
    const balanceMap = processBalances2(
      balances.filter((balance) => balance.chainId === sourceNetwork.id)
    );
    console.log("BALANCE MAP: ", balanceMap);
    setSourceNetworkBalances(balanceMap);
  }, [balances, sourceNetwork]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          fromToken?.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        ) {
          setCalldata("0x");
          return;
        }
        if (sourceAmount && fromToken && addressToSend) {
          console.log("CHECKING");
          let calldata;
          if (fromToken.address == '0xEnclave') {
            let usdcQuote
            try {
              usdcQuote = await computeQuote(
                walletAddress,
                sourceNetwork.id,
                Math.floor(parseFloat(sourceAmount) * 1000000),
                "AMOUNT_OUT",
              );  
              console.log("USDC QUOTE: ", usdcQuote);
              if (!usdcQuote.withdrawals) {
                setError(false);
              }
              calldata = getERC20TransferCallData(
                sourceAmount,
                tokens['USDC'][sourceNetwork.id],
                addressToSend,
                6
              );
            } catch (e) {
              setError(true);
              console.log("Error", e);
            }
          } else {
            calldata = getERC20TransferCallData(
              sourceAmount,
              fromToken.address,
              addressToSend,
              fromToken.decimals
            ); 
          }
          setCalldata(calldata);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchDataDebounce = debounce(fetchData, 500);
    setLoading(true);
    setError(false);
    fetchDataDebounce();

    return () => fetchDataDebounce.cancel();
  }, [sourceAmount, fromToken?.address, addressToSend, fromToken?.decimals]);

  const handleSubmit = (addressToSend) => {
    if (fromToken.address == '0xEnclave') {}
    if (fromToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
      const value = ethers.parseEther(sourceAmount);
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: "0x",
            value: value,
            targetContractAddress: addressToSend,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          addressToSend.substring(0, 6) +
          "..." +
          addressToSend.substring(addressToSend.length - 4)
        }`
      );
    } else {
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: calldata,
            targetContractAddress: fromToken.address == '0xEnclave' ? tokens['USDC'][sourceNetwork.id] : fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          addressToSend.substring(0, 6) +
          "..." +
          addressToSend.substring(addressToSend.length - 4)
        }`,
        undefined,
        undefined,
        orderData,
        deficit <= 0 ? 'GASLESS' : 'ABSTRACTED_INTERNAL'
      );
    }
  };

  const handleNumberClick = (number) => {
    if (sourceAmount === "0" && number === "0") return;

    if (sourceAmount === "0" && number !== ".") {
      setSourceAmount(number);
      return;
    }

    setSourceAmount(sourceAmount + number);
  };

  const handleBackspaceClick = () => {
    setSourceAmount((prevAmount) => prevAmount.slice(0, -1));
  };

  const handlePresetClick = (preset) => {
    const unformattedBalance = sourceNetworkBalances[fromToken.address.toLowerCase()]?.total;
    const balance = ethers.parseUnits(unformattedBalance || "0", 18);    
    if (preset === "MAX" || preset === 100) {
      setSourceAmount(unformattedBalance);
    } else {
      const percentage = ethers.parseUnits(String(preset), 16); // Move 16 decimals to make it a percentage
      const balance = ethers.parseUnits(unformattedBalance || "0", 18);
      const amount = balance * percentage / ethers.parseUnits("100", 16); // Divide by 100 with same precision
      console.log('About to transfer amount:', ethers.formatUnits(amount, 18));
      setSourceAmount(ethers.formatUnits(amount, 18));
    }
  };

  return (
    <div className={(styles.page, styles.pageOverrides)}>
      <div
        style={{
          padding: "8px 20px 0 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span onClick={() => window.history.back()}>
            <ArrowBack />
          </span>
          <h2>Transfer</h2>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
            flexDirection: "column",
          }}
        >
          <div>
            <input
              className={styles.swapInput}
              value={sourceAmount}
              type="text"
              inputMode="decimal"
              readOnly // Disable typing from native keyboard
              placeholder="0"
              onChange={(e) => {
                let value = e.target.value;
                console.log("VALUE: ", value);
                if (value.startsWith("00")) {
                  value = value.slice(value.indexOf("0") + 1);
                } else if (value.startsWith("-")) {
                  value = "0";
                }
                if (isNaN(value)) {
                  value = "";
                }
                setSourceAmount(value);
              }}
              style={{
                border: "none",
                background: "transparent",
                fontSize: "3rem",
                color: "white",
                width: `${sourceAmount?.length + 1}ch`,
                maxWidth: "180px",
                minWidth: "2ch",
                // Dynamic width based on content
                textAlign: "right",
              }}
            />
            <span
              className={styles.swapInputSymbol}
              style={{
                fontSize: "3rem",
                color: "gray",
                marginLeft: "10px",
                whiteSpace: "nowrap",
                width: "60%",
              }}
            >
              {fromToken?.symbol}
            </span>
          </div>

          <div className={styles.swapBalanceText}>
            <small>
              Balance:&nbsp;
              {fromToken && 
                (fromToken.address === '0xEnclave'
                  ? smartBalanceObject.netBalance / 1e6
                  : sourceNetworkBalances[
                fromToken?.chainIds
                  ?.find((chain) => chain.chainId === sourceNetwork.id)
                  ?.address?.toLowerCase()
              ]
                ? parseFloat(
                    sourceNetworkBalances[
                      fromToken?.chainIds
                        ?.find((chain) => chain.chainId === sourceNetwork.id)
                        ?.address?.toLowerCase()
                    ]?.total
                  )
                : 0)}
            </small>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "12vh",
            padding: "20px",
            left: "0px",
          }}
        >
          {fromToken && (
            <SwapTokenSelect
              cashTransferMode={cashTransferMode}
              setCashTransferMode={setCashTransferMode}
              amount={sourceAmount}
              type={"Transfer"}
              selectedToken={fromToken}
              setSelectedToken={setFromToken}
              tokenList={destinationTokenList}
              balances={sourceNetworkBalances}
              networkList={networkList}
              selectedNetwork={sourceNetwork}
              setSelectedNetwork={setSourceNetwork}
              changeAllowed={true}
            />
          )}
          <div
            style={{
              marginBottom: "20px",
              border: "1px solid #575757",
              ...(validToAddress && {
                border: "1px solid var(--yellow-primary)",
              }),
            }}
            className={styles.swapInputWrapper}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "space-between",
              }}
            >
              <label>To</label>
              {validToAddress && <BadgeCheck size={16} />}
            </div>
            <div style={{ display: "flex" }}>
              <input
                className={`${styles.swapInput}`}
                value={address}
                type="text"
                placeholder="Enter username or wallet address"
                onChange={(e) => {
                  handleAddressChange(e.target.value);
                }}
                style={{ fontSize: 20 }}
              />
            </div>
          </div>

          <Numpad
            tokenSymbol={fromToken?.symbol}
            onNumberClick={handleNumberClick}
            onBackspaceClick={handleBackspaceClick}
            onPresetClick={handlePresetClick}
          />
          <button
            className="btn-primary w-full"
            disabled={!validSend}
            onClick={() => handleSubmit(addressToSend)}
          >
            {loading ? (
              <CircularProgress size={17} color="inherit" />
            ) : validSend ? (
              "Send"
            ) : !validAmount ? (
              "Enter transfer amount"
            ) : !withinBalance || error ? (
              "Insufficient balance - Deposit more funds"
            ) : !validToAddress ? (
              "Invalid address"
            ) : (
              "Unknown error"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferPage;
